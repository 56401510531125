.course_page h1{
    color: #000;
    font-weight: bold;
    font-size: 3rem;
}

.course_page h2{
    color: #000;
    font-weight: bold;
    font-size: 2.6rem;
}

.course_page h3{
    color: #000;
    font-weight: bold;
    font-size: 2.3rem;
}

.course_page h4{
    color: #000;
    font-weight: bold;
    font-size: 2rem;
}

.course_page h5{
    color: #000;
    font-weight: bold;
    font-size: 1.5rem;
}

.course_page h6{
    color: #000;
    font-weight: bold;
    font-size: 1rem;
}

.course_page a{
    text-decoration: underline;
    font-weight: 700;
    color: rgb(23, 23, 120);
}

.course_page h1, .course_page h2, .course_page h3, .course_page h4, .course_page h5,.course_page h6{
    padding: .5rem 0;
}

.course_page a, .course_page p, .course_page div, .course_page img{
    padding: 0.4rem 0;
}