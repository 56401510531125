.article h1{
    font-size: 2rem;
    margin: .5rem 0;
}
.article h2{
    font-size: 1.9rem;
    margin: .5rem 0;
}
.article h3{
    font-size: 1.8rem;
    margin: .5rem 0;
}
.article h4{
    font-size: 1.7rem;
    margin: .5rem 0;
}

.article p{
    padding: .5rem 0;
}

.article div{
    padding: .3rem 0;
}

.article img{
    padding: .3rem 0;
}