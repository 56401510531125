@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



body {
}

.custom-underline{
  display: inline-block;
}

.custom-underline::after {
  content: "";
  display: block;
  width: 70%;
  margin: 8px auto;
  height: 3px;
  background-color: #700ADA;

}

.about-header {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("https://cdn.pixabay.com/photo/2017/10/04/16/39/lake-2816802_1280.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  width: 100%;
}

.carousel.carousel-slider .control-arrow:hover {
  background: transparent !important;
}

@media (max-width: 600px) {
  .carousel .control-prev.control-arrow {
    top: 20%;
  }

  .carousel .control-next.control-arrow {
    top: 20%;
  }
}

.cta-section {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),
    url("https://cdn.pixabay.com/photo/2021/10/09/06/23/stock-market-6693060_1280.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  width: 100%;
}


/* Customize the scrollbar track */
div.scrollable::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  height: 12px; /* Height of the scrollbar */
}

/* Customize the scrollbar thumb (the draggable part) */
div.scrollable::-webkit-scrollbar-thumb {
  background-color: #8e8f91; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

/* Customize the scrollbar track (the background of the scrollbar) */
div.scrollable::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Color of the scrollbar track */
  border-radius: 6px; /* Rounded corners for the track */
}

/* Hover effect on the thumb */
div.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #235a8a; /* Color on hover */
}
