.swiper {
    width: 100%;
    height: 80vh;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper {
    margin-left: auto;
    margin-right: auto;
  }
  

  /*custom*/
  .slider-container_1{
    height: 100%;
    background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url('https://cdn.pixabay.com/photo/2021/08/06/00/37/stock-trading-6525081_1280.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slider-container_2{
    height: 100%;
    background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url('https://cdn.pixabay.com/photo/2020/11/24/04/13/finance-5771541_1280.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slider-container_3{
    height: 100%;
    background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url('https://cdn.pixabay.com/photo/2015/10/09/20/37/meeting-979899_1280.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-next{
    color: #fff !important;
  }
  .swiper-button-prev{
    color: #fff !important;
  }

  .swiper-pagination-bullet{
    width: 40px !important;
    height: 40px !important;
    background-color: #fff !important;
  }